import { ReactNode } from 'react'

import Img from '@/components/common/Img'
import { 용쿠_아이콘_이미지 } from '@/constants/image'
import { Game } from '@/types/game'
import { useIsWebview } from '@/utils/hooks/useIsWebview'

interface Props {
  h1Tag?: string
  withTitle?: boolean
  withDescription?: boolean
  withTitleImage?: boolean
  titleImage?: string
  title?: string
  description?: string
  actions?: ReactNode
  game?: Game | null
}

function LayoutHeader({
  h1Tag,
  withTitle,
  withDescription,
  title,
  titleImage,
  withTitleImage = true,
  description,
  actions,
  game,
}: Props) {
  const [isWebview] = useIsWebview()

  const loading = game === undefined ? true : false

  return (
    <div className="flex w-full flex-col items-start px-4">
      <h1 className="hidden">{h1Tag}</h1>
      <div className="flex w-full items-center justify-between">
        {withTitle && title && !isWebview && (
          <div className="relative mb-2 mt-3 flex items-center justify-center">
            {withTitleImage &&
              (loading ? (
                <div className="z-[2] h-8 w-8 animate-pulse rounded-md bg-gray-300 object-contain" />
              ) : (
                <Img
                  className="z-[2] h-8 w-8 rounded-md object-contain"
                  src={titleImage ?? 용쿠_아이콘_이미지}
                />
              ))}
            {loading ? (
              <span className="ckk relative z-[2] mb-1 ml-2 animate-pulse rounded-md bg-gray-300 text-lg">
                {title}
              </span>
            ) : (
              <span className="ckk relative z-[2] mb-1 ml-2 text-lg">
                {title}
              </span>
            )}
            <span className="absolute -left-2 -right-2 bottom-1/2 z-[1] h-4 translate-y-1/2 rounded-md bg-[#ffeea0]" />
          </div>
        )}
        {actions}
      </div>
      {withDescription && description && !isWebview && (
        <div className="ckk mb text-sm text-gray-700">{description}</div>
      )}
    </div>
  )
}

export default LayoutHeader
